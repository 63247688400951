 .signin-wrapper{
    padding-top: 7rem;
 }
 .container{
     max-width: max-content;
     border: 2px solid var(--blue);
     border-radius: 10px ;
     margin: auto;
 }
 .container>div{
    width: 100%;
    gap: 0.5rem;
    margin-bottom: 1rem;
 }

 .row-1>input{
    padding: 5px;
    width: 100%;
 }

 .password{
    justify-content: space-between;
    border: 1px solid rgb(118, 118, 118);
    width: 100%;
    row-gap: 0;
  }

 .password>input{
    border: none;
    outline: none;
    padding: 5px;
    max-width: max-content;
    width: 100%;
 }

.password span{
    padding: 0.5rem;
    cursor: pointer;
}

.row-3{
    justify-content: space-around;
}

.isRemember{
    font-weight: 500;
}

.forgot_password{
    font-weight: 500;
    text-decoration-line:underline;
    text-decoration-color: var(--blue);
}

.forgot_password:hover{
       color: var(--blue);
       font-weight: bold;
}
.row-4{
    background: var(--blue-gradient);
    padding: 5px;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 4px;
    transition: all 300ms ease-in;
}
.row-4:hover{
    cursor: pointer;
    scale: 1.1;
}
.row-6{
    justify-content: space-between;
}

.row-6>div:hover{
    cursor: pointer;
    scale: 1.1;
}

.row-7{
    justify-content: space-around;
    font-weight: 500;
}
.signup-link{
    text-decoration: underline;
    text-decoration-color: var(--blue);
}
.signup-link:hover{
      color: var(--blue);
      text-decoration: underline;
      font-weight: bold;
}
